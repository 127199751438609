/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js';
import 'regenerator-runtime/runtime';
import 'promise-polyfill/src/polyfill';
import 'pepjs';

import setAsap from 'setasap';

interface PromiseConstructorWithImmediateFn extends PromiseConstructor {
  _immediateFn: setAsap;
}

if (window) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  (window.Promise as PromiseConstructorWithImmediateFn)._immediateFn = setAsap;
}
